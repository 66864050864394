/**
 * Homepage scroll functionality
 */

export default {
	init() {
		this.trigger = document.querySelector('#home-scroll');

		if(this.trigger) {
			this.bind();
		}
	},

	bind() {
		this.trigger.addEventListener('click', (e) => this.scroll(e));
	},

	scroll(e) {
		e.preventDefault();
		document.querySelector('#home-top').scrollIntoView();
	}
}
