import '../styles/delta.scss';

// import PappasLeadsForm from '@pappas/leads-form/src/components/Form.vue';
import PappasLeadsForm from '@pappas/leads-form';
// import PappasEclub from '../../../pappas-eclub/src/plugin.js';
import PappasEclub from '@pappas/eclub';
// import PappasReservationForm from '../../../../../pappas-reservation-form/src/js/components/ReservationForm.vue';
import PappasReservationForm from '@pappas/reservation-form/src/js/components/ReservationForm.vue';

import HomeSlider from './modules/home-slider';
import HomeScroll from './modules/home-scroll';
import Slider from './modules/slider';
import SpecialsCarousel from './modules/specials-carousel';
import Icons from './modules/icons';
import 'lazysizes';

import MapMarker from './components/MapMarker.vue';
import NavOverlay from './components/NavOverlay.vue';
import Faqs from './components/faqs.vue';
import LocationMap from './components/LocationMap.vue';

import MenuView from './components/Menu/MenuView.vue';

import VueGoogleMaps from 'vue-google-maps-community-fork';
import { createApp } from 'vue';

const app = createApp({
    data() {
        return {
            /** @type {String} navigation state */
            navState: null,
        };
    },
});

app.use(VueGoogleMaps, {
    load: { key: window.wpapi.gapi },
});

app.use(PappasLeadsForm);
app.use(PappasEclub);

app.component('map-marker', MapMarker);
app.component('faqs', Faqs);
app.component('menu-view', MenuView);
app.component('nav-overlay', NavOverlay);
app.component('pappas-reservation-form', PappasReservationForm);
app.component('location-map', LocationMap);

app.mount('#app');

// initiate modules that bind after content load
window.addEventListener('DOMContentLoaded', (e) => {
    HomeScroll.init();
    HomeSlider.init();
    Slider.init();
    SpecialsCarousel.init();
});

// initialize icons
new Icons();
