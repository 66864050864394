<template>
    <GMapMap :center="center" :zoom="12" map-type-id="terrain" :options="opts">
        <GMapMarker :position="center" :icon="'/app/themes/pappasdeltablues/images/map-marker.png'" />
    </GMapMap>
</template>

<script setup>
import mapStyles from '../modules/map-styles';

const props = defineProps({
    latitude: { type: Number, required: true },
    longitude: { type: Number, required: true },
})

const center = { lat: props.latitude, lng: props.longitude };

const opts = {
    disableDefaultUI: true,
    styles: mapStyles,
}

/** @type {Object} google maps options */
// mapOpts: {
// 				disableDefaultUI: true,
// 				styles: MapStyles,
// 			}
</script>
