<template>
    <section id="nav-overlay" :class="{ 'is-active': isActive }">
        <div id="nav-overlay-wrapper" :class="{ 'is-active': navState == 'nav' || (isMobile && navState) }">
            <div class="nav-overlay-header">
                <div class="navbar">
                    <div class="row row-justify-between row-align-center">
                        <div class="col col-location">
                            <button class="current-location" @click.prevent="toggleLocation">
                                <map-marker></map-marker>
                                <span v-html="location.short_name"></span>
                            </button>
                        </div>

                        <a href="/" class="col col-logo">
                            <img :src="'/app/themes/pappasdeltablues/images/logo.png'" alt="Pappas Delta Blues Smokehouse" />
                        </a>

                        <a href="/" class="col col-badge">
                            <img :src="'/app/themes/pappasdeltablues/images/generic-badge.png'" alt="Pappas Delta Blues Smokehouse" />
                        </a>

                        <div class="col col-nav-toggle">
                            <button class="hamburger hamburger--squeeze" type="button" @click.prevent="toggleNav" :class="{ 'is-active': navState }" aria-label="Menu">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div id="nav-overlay-picker" :class="{ 'is-active': locationPickerActive }" v-if="isMobile">
                    <a href="" v-html="location.short_name" @click.prevent="locationPickerActive = !locationPickerActive"></a>
                    <ul class="bare">
                        <li :class="{ 'is-active': true }">{{ location.name }}</li>
                    </ul>
                </div>
            </div>

            <div id="nav-overlay-body">
                <div id="nav-overlay-track" :class="{ 'is-location': isMobile && navState == 'location' }">
                    <div id="nav-overlay-location">
                        <div>
                            <h6><a :href="'/' + location.slug" v-html="location.short_name"></a></h6>
                            <p id="nav-overlay-location-directions">
                                <a :href="location.directions_url" rel="noopener" target="_blank" v-html="location.address_formatted"></a>
                                <br />
                                <a :href="'tel:' + location.phone" rel="noopener" target="_blank" v-html="location.phone"></a>
                            </p>
                            <h6>Hours</h6>
                            <p v-html="location.hours_formatted"></p>

                            <a :href="location.reservations_url" class="button">Book Reservation</a>

                            <a v-if="!isMobile" :href="location.order_online_url" class="button button-red" target="_blank" rel="noopener">Order Online</a>
                        </div>
                    </div>
                    <div id="nav-overlay-nav">
                        <div>
                            <nav id="nav-overlay-nav-primary">
                                <ul class="bare">
                                    <li v-for="item in navItems.primary" :class="{ current: item.current }">
                                        <a :href="item.url" v-html="item.label"></a>
                                    </li>

                                    <li v-if="location.order_online_url">
                                        <a :href="location.order_online_url" rel="noopener" target="_blank">Order Online</a>
                                    </li>
                                </ul>
                            </nav>

                            <button class="button button-red" v-if="isMobile" @click.prevent="$root.navState = 'location'">Directions &amp; Hours</button>

                            <a :href="location.reservations_url" class="button button-red" v-if="isMobile">Book Reservation</a>

                            <nav id="nav-overlay-nav-secondary">
                                <ul class="bare">
                                    <li v-for="item in navItems.secondary" :class="{ current: item.current }">
                                        <a :href="item.url" v-html="item.label"></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="nav-overlay-location-wrapper" :class="{ 'is-active': navState == 'location' }" v-if="!isMobile">
            <div class="nav-overlay-header">
                <div class="navbar">
                    <div class="row row-justify-between row-align-center">
                        <div class="col col-location">
                            <button class="current-location" @click.prevent="toggleLocation">
                                <map-marker></map-marker>
                                <span v-html="location.short_name"></span>
                            </button>
                        </div>

                        <a href="/" class="col col-logo">
                            <img :src="'/app/themes/pappasdeltablues/images/logo.png'" alt="Pappas Delta Blues Smokehouse" />
                        </a>

                        <div class="col col-nav-toggle">
                            <button class="hamburger hamburger--squeeze" type="button" @click.prevent="toggleLocation" :class="{ 'is-active': navState }" aria-label="Menu">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="nav-overlay-location-body" v-if="!isMobile">
                <div>
                    <NavOverlayLocation :location="location"></NavOverlayLocation>
                    <NavOverlayLocation :location="planoLocation"></NavOverlayLocation>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MapMarker from './MapMarker.vue';
import NavOverlayLocation from './NavOverlayLocation.vue';

export default {
    /** @type {Object} child componentsm */
    components: {
        MapMarker,
        NavOverlayLocation,
    },

    /** @type {Object} static properties */
    props: {
        /** @type {Array} the webster location */
        location: Object,

        /** @type {String} current nav state */
        navState: String,

        /** @type {Object} navigation item chunks */
        navItems: Object,
    },

    /** @type {Object} dynamic properties */
    data() {
        return {
            /** @type {Array} available states */
            states: [null, 'nav', 'location'],

            /** @type {String} mutable currently active state */
            activeState: null,

            /** @type {Number} mobile viewport threshold */
            mobileThreshold: 670,

            /** @type {Boolean} are we within the mobile viewport bounds */
            isMobile: false,

            /** @type {Boolean} is the mobile location picker active */
            locationPickerActive: false,

            planoLocation: {
                split_name: 'Plano,<br> Texas',
                closed: true,
            },
        };
    },

    /** @type {Object} computed properties */
    computed: {
        isActive() {
            return this.navState;
        },
    },

    /** @type {Object} property watchers */
    watch: {
        navState(newValue, oldValue) {
            const $body = document.querySelector('body');

            if (newValue) {
                $body.classList.add('no-scroll');
                if (newValue == 'nav') $body.classList.add('nav-open');
            } else {
                $body.classList.remove('no-scroll', 'nav-open');
            }
        },
    },

    /** vue instance created lifecycle hook */
    created() {
        window.addEventListener('resize', this.handleResize);
    },

    /** vue instance mounted lifecycle hook */
    mounted() {
        this.handleResize(null);
    },

    /** vue instance destroyed lifecycle hook */
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        toggleNav() {
            if (this.navState == 'nav') {
                this.$root.navState = null;
            } else {
                this.$root.navState = 'nav';
            }
        },

        toggleLocation() {
            if (this.navState == 'location') {
                this.$root.navState = null;
            } else {
                this.$root.navState = 'location';
            }
        },

        /**
         * Handle the window resize event
         *
         * @param  {Event} ev
         */
        handleResize(ev) {
            if (window.innerWidth <= this.mobileThreshold) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
    },
};
</script>
