/**
 * FontAwesome icons
 */

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/fontawesome-free-brands';

export default class {
	constructor () {
		library.add(faFacebookF, faInstagram, faTwitter);
		dom.watch();
	}
}