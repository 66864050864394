/**
 * Homepage slider
 */

import Glide from '@glidejs/glide';

export default {
	/** @type {Element} slider element wrapper */
	$wrapper: null,

	/**
	 * Initialization
	 */
	init() {
		this.$wrapper = document.querySelector('#home-slider');

		if(!this.$wrapper) return;

		new Glide(this.$wrapper, {
			autoplay: 6500,
		}).mount();
	}
}