<template>
	<svg class="icon-map-marker" width="16px" height="23px" viewBox="0 0 16 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g transform="translate(-223.000000, -64.000000)">
            <g transform="translate(222.000000, 64.000000)">
                <g transform="translate(0.525000, 0.000000)">
                    <g transform="translate(0.538333, 0.000000)">
                        <path d="M15.8289711,8.15550849 C15.8289711,12.6256093 7.91444873,22.5830509 7.91444873,22.5830509 C7.91444873,22.5830509 8.17869416e-06,12.6256093 8.17869416e-06,8.15550849 C8.17869416e-06,3.68540763 3.54342742,0.0615847478 7.91444873,0.0615847478 C12.2855518,0.0615847478 15.8289711,3.68540763 15.8289711,8.15550849" class="icon-map-marker-outer"></path>
                    </g>
                    <path d="M11.8716667,7.93333333 C11.8716667,9.81110852 10.3494241,11.3333333 8.4717063,11.3333333 C6.59390922,11.3333333 5.07166667,9.81110852 5.07166667,7.93333333 C5.07166667,6.05555815 6.59390922,4.53333333 8.4717063,4.53333333 C10.3494241,4.53333333 11.8716667,6.05555815 11.8716667,7.93333333" class="icon-map-marker-inner"></path>
                </g>
            </g>
        </g>
	</svg>
</template>

<script>
export default {}
</script>