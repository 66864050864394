<template>
    <section :id="type + '-menu'" class="menu">
        <MenuSidebar :menus="menus" :location="location" :activeMenu="activeMenu" :badge="badge" :download="download" :order-online="orderOnline" />

        <div class="menu-content" :id="menu.slug">
            <slot name="banner"></slot>
            
            <TransitionGroup name="fade" tag="div">
                <div v-for="(menu, index) in menus" class="menu-sections" :key="'menu-' + index" >
                    <template v-if="index == activeMenu" v-for="(section, idx) in menu.sections">
                        <MenuSection :section="section" :index="idx" />
                    </template>
                </div>
            </TransitionGroup>

            <slot></slot>
        </div>
    </section>

</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import MenuSection from './MenuSection.vue';
import MenuSidebar from './MenuSidebar.vue';
import StickyJs from 'sticky-js';

const props = defineProps({
    badge: { type: String, required: true },
    type: { type: String, required: true },
    download: { type: String, default: null },
    orderOnline: { type: String, default: null },
    menus: { type: Array, required: true },
    location: { type: Object, required: true },
});

const activeMenu = ref(0);

const menu = computed(() => props.menus[activeMenu.value]);

onMounted(() => new StickyJs('.menu-sidebar-body'));
</script>
