/**
 * Generic slider
 */

import Glide from '@glidejs/glide';

export default {

	/** @type {Element} slider element wrapper */
	$wrapper: null,

	/** @type {tns} slider instance */
	slider: null,

	/**
	 * Initialization
	 */
	init() {
		this.$wrapper = document.querySelector('.specials-carousel');

		if(!this.$wrapper) return;

		new Glide(this.$wrapper, {gap: 24}).mount();
	}

}