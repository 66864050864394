<template>
    <div :class="wrapperClass">
        <h6 v-html="item.name"></h6>

        <p v-if="item.description || item.price" class="menu-item-description">
            {{ item.description }}
            <template v-if="item.price && item.price != 0">
                <template v-if="item.description">|</template>
                {{ item.price }}
            </template>
        </p>

        <p v-if="item.description_alt" v-html="item.description_alt" class="menu-item-description-alt"></p>

        <p v-if="item.options.length" class="menu-item-options">
            <template v-for="(option, idx) in item.options">
                <template v-if="idx">&nbsp;|&nbsp;</template>
                {{ option.name }} {{ option.price }}
            </template>
        </p>

        <p v-if="!item.description && !item.description_alt && !item.options.length"></p>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    item: { type: Object, required: true },
    index: { type: Number, required: true },
});

const wrapperClass = computed(() => {
    const classlist = ['menu-item'];

    classlist.push('menu-item-' + (props.index + 1));

    if (props.item.description || props.item.price) classlist.push('has-description');
    if (props.item.description_alt) classlist.push('has-description-alt');
    if (props.item.options.length) classlist.push('has-options');

    return classlist;
});
</script>
