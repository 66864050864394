<template>
    <section :id="'menu-section-' + section.slug" :class="wrapperClass">
        <h5 v-html="section.name"></h5>
        <p v-if="section.description" v-html="section.description"></p>

        <MenuItem v-for="(item, idx) in section.items" :item="item" :index="idx">
        
        </MenuItem>

        <div v-if="section.images.length" class="menu-section-image">
            <div class="glide menu-carousel">
                <div class="glide__track" data-glide-el="track">
                    <ul class="glide__slides">
                        <li v-for="image in section.images" class="glide__slide">
                            <div :style="'background-image:url(' + image.url + ')'"></div>
                        </li>
                    </ul>
                </div>

                <div class="glide__bullets" data-glide-el="controls[nav]" v-if="section.images.length > 1">
                    <button v-for="(image, imageIndex) in section.images" class="glide__bullet" :data-glide-dir="'=' + imageIndex">Image #{{ imageIndex }}</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { computed } from 'vue';
import MenuItem from './MenuItem.vue';

const props = defineProps({ 
    section: { type: Object, required: true },
    index: { type: Number, required: true } 
});

const wrapperClass = computed(() => {
    const classlist = ['menu-section'];

    classlist.push('has-items-' + props.section.items.length);
    classlist.push('menu-section-' + (props.index + 1));

    if (props.section.description) classlist.push('has-description');
    if (props.section.images.length) classlist.push('has-image');

    return classlist;
});
</script>
